<template>
  <font-awesome-icon icon="fa-seedling" style="font-size: 10px; margin-right: 5px;"/>
</template>

<script>
export default {
  name: "Env"
}
</script>

<style scoped>

</style>