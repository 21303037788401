<template>
  <div class="user-wrap">
    <el-scrollbar height="720px">
      <div class="articles-list my-articles">
        <ul>
          <li>
            <router-link to="/article-add">
              <div class="icon-myadd">
                <font-awesome-icon icon="fa-solid fa-file-circle-plus" style="font-size: 60px; color: #090970"/>
              </div>
            </router-link>
          </li>

          <li v-for="article in articles" :key="article.id">
            <div @click="handleArticleClick(article)" @mouseover="getStatus(article.approvedStatus)">
              <div>
                <el-popover placement="top" trigger="hover" width="200">
                  <template #reference>
                    <div>
                      <div class="al-text">
                        <div class="al-title">{{ article.name }}</div>
                        <div class="al-from">{{ 'from: ' + article.source }}</div>
                        <el-scrollbar height="50px" style="font-size: 13px; color: #3F3D56; line-height: 1.2; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                          {{ article.overview }}
                        </el-scrollbar>
                      </div>
                      <div class="al-icon" :class="'bg-' + article.topic.split(' ')[0]">
                        <div class="ali-item">
                          <span class="icon-'.$topic.'"><component :is="icons[article.topic]"/>{{ article.topic }}</span>
                        </div>
                        <div class="ali-item">
                          <span class="icon-'.$category.'"><component :is="icons[article.category]"/>{{ article.category }}</span>
                          <span class="icon-time"><component style="margin-right: 3px" :is="icons['Time']"/>{{ article.readingTimes + ' mins' }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <router-link :to="{ path: '/account/moder', query: { uid: route.query.uid } }">
                    <h6 style="text-align: center; font-family: 'Poppins-Regular'">Moderation Progress</h6>
                    <el-progress
                        :text-inside="true"
                        :stroke-width="20"
                        :percentage="percent"
                        :status="percentCol"
                    ><span style="font-family: 'Poppins-Light'">{{ percentCont }}</span></el-progress>
                  </router-link>
                </el-popover>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { get, post } from "@/api";
import { ref, shallowRef, onMounted } from 'vue';
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import Tech from "@/components/icons/Tech.vue";
import Edu from "@/components/icons/Edu.vue";
import Covid from "@/components/icons/Covid.vue";
import Health from "@/components/icons/Health.vue";
import Society from "@/components/icons/Society.vue";
import Env from "@/components/icons/Env.vue";
import Busi from "@/components/icons/Busi.vue";
import Ent from "@/components/icons/Ent.vue";
import Poli from "@/components/icons/Poli.vue";
import News from "@/components/icons/News.vue";
import Advice from "@/components/icons/Advice.vue";
import Analy from "@/components/icons/Analy.vue";
import Interview from "@/components/icons/Interview.vue";
import Discuss from "@/components/icons/Discuss.vue";
import Chron from "@/components/icons/Chron.vue";
import { TimeIcon } from "@layui/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "ProposedArticles",
  components: { FontAwesomeIcon },
  setup() {
    const icons = shallowRef({
      "Technology & Science": Tech,
      "Education": Edu,
      "COVID-19": Covid,
      "Health": Health,
      "Society & Culture": Society,
      "Environment": Env,
      "Business & Finance": Busi,
      "Entertainment & Arts": Ent,
      "Politics": Poli,
      "News": News,
      "Advice": Advice,
      "Analysis": Analy,
      "Interview": Interview,
      "Discussion": Discuss,
      "Chronicle": Chron,
      "Time": TimeIcon
    });
    const articles = ref([]);
    const route = useRoute();
    const router = useRouter();
    const uid = ref(-1);
    const uname = ref("");
    const percent = ref(0);
    const percentCol = ref("warning");
    const percentCont = ref("pending");

    if (sessionStorage.getItem("token")) {
      post(`/user/logged`).then(res => {
        if (res.res == 1) {
          uid.value = res.user.uid;
          uname.value = res.user.uname;
        } else {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem('Authorization');
        }
      });
    }

    function loadArticles() {
      get('/article/id/user/' + route.query.uid).then(res => {
        articles.value = res.articles;
      });
    }

    function getStatus(approvedStatus) {
      if (approvedStatus === "under_assessment") {
        percent.value = 20;
        percentCol.value = "warning";
        percentCont.value = "Article Pending";
      } else if (approvedStatus === "article_approved") {
        percent.value = 60;
        percentCol.value = "success";
        percentCont.value = "Article Approved";
      } else if (approvedStatus === "await_modification") {
        percent.value = 40;
        percentCol.value = "warning";
        percentCont.value = "Awaiting Modification";
      } else if (approvedStatus === "question_uploaded") {
        percent.value = 80;
        percentCol.value = "success";
        percentCont.value = "Question Uploaded";
      } else if (approvedStatus === "question_disapproved") {
        percent.value = 50;
        percentCol.value = "exception";
        percentCont.value = "Question Disapproved";
      } else if (approvedStatus === "disapproved") {
        percent.value = 100;
        percentCol.value = "exception";
        percentCont.value = "Disapproved";
      } else if (approvedStatus === "all_approved") {
        percent.value = 100;
        percentCol.value = "success";
        percentCont.value = "All Approved";
      } else {
        percent.value = 0;
        percentCol.value = "exception";
        percentCont.value = "Unknown Status";
      }
    }

    function handleArticleClick(article) {
      switch (article.approvedStatus) {
        case "under_assessment":
          ElMessage({
            message: "This article is under assessment and cannot be modified.",
            type: "warning",
          });
          break;
        case "await_modification":
        case "under_modification":
          changeArticle(article);
          break;

        case "question_uploaded":
        case "question_disapproved":
          changeQuestion(article);
          break;
        case "article_approved":
          addQuestion(article);
          break;
        case "all_approved":
          ElMessage({
            message: "This article has been fully approved and cannot be modified.",
            type: "success",
          });
          break;
        default:
          ElMessage({
            message: "Article Disapproved, You could not change them in this case",
            type: "error",
          });
          break;
      }
    }

    function changeArticle(article) {
      sessionStorage.setItem("newArticle", JSON.stringify(article));
      sessionStorage.setItem("reviewStatus", "changing");
      router.push("/article-add");
    }

    function changeQuestion(article) {
      sessionStorage.setItem("newArticle", JSON.stringify(article));
      sessionStorage.setItem("reviewStatus", "change");
      sessionStorage.setItem("articleId", article.id);
      sessionStorage.setItem("userId",uid.value);
      router.push("/questions-add");
    }
    
    function addQuestion(article){
      sessionStorage.setItem("newArticle", JSON.stringify(article));
      sessionStorage.setItem("reviewStatus", "addQuestion");
      sessionStorage.setItem("articleId", article.id);
      router.push("/questions-add");
    }

    onMounted(() => {
      loadArticles();
    });

    return {
      articles,
      icons,
      getStatus,
      percent,
      percentCol,
      percentCont,
      handleArticleClick,
      changeArticle,
      changeQuestion,
      route
    };
  }
}
</script>

<style scoped>


*,*:before,*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
}
.wrapper {
  width: 1240px;
  margin: 0 auto;
}
.user-wrap {
  height: 720px;
  display: flex;
  width: 1240px;
  margin: 25px auto 25px auto;
  justify-content: space-between;
}

.al-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
  line-height: 1.2;
}

.articles-list.my-articles {
  display: block;
  padding: 35px 50px;
}
.articles-list.my-articles li {
  width: 260px;
  height: 237px;
  margin-right: 40px;
  margin-bottom: 90px;
  position: relative;
  overflow: unset;
}
.articles-list.my-articles li:nth-child(3n) {
  margin-right: 0;
}
.articles-list.my-articles .al-text {
  display: block;
  padding: 20px 25px 20px 25px;
  height: 180px;
}
.articles-list .al-text .al-title {
  font-size: 18px;
  line-height: 1.6;
  font-family: "Poppins-ExtraBold";
}
.articles-list.my-articles .al-text .al-from {
  font-size: 16px;
  font-style: italic;
  color: #969696;
  margin: 12px 0;
  font-weight: bold;
}
.articles-list.my-articles .al-icon {
  display: block;
  height: 58px;
  color: #FFFFFF;
  font-size: 12px;
  padding: 5px 20px;
  border-radius: 0 0 30px 30px;
}
.articles-list.my-articles .icon-myadd {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
}

.articles-list {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
.articles-list ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.articles-list li {
  width: 290px;
  height: 280px;
  background-color: #FFFFFF;
  border-radius: 30px;
  margin-bottom: 25px;
  margin-right: 26px;
  overflow: hidden;
  box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.2);
}
.articles-list li:hover {
  box-shadow: 0px 2px 20px 0px rgba(59,93,245,0.5);
}
.articles-list li:nth-child(4n) {
  margin-right: 0;
}
.articles-list.my-articles {
  display: block;
  padding: 35px 50px;
}
.articles-list.my-articles li {
  width: 260px;
  height: 237px;
  margin-right: 40px;
  margin-bottom: 90px;
  position: relative;
  overflow: unset;
}
.articles-list.my-articles li:nth-child(3n) {
  margin-right: 0;
}
.articles-list.my-articles .icon-myadd {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -40px;
}

.articles-list .al-icon {
  display: block;
  height: 65px;
  color: #FFFFFF;
  font-size: 12px;
  padding: 10px 20px;
  background-color: #726AFA;
}

.articles-list .al-icon.bg-Society {
  background-color: #726AFA;
}
.articles-list .al-icon.bg-Technology {
  background-color: #5473FF;
}
.articles-list .al-icon.bg-COVID-19 {
  background-color: #E06546;
}
.articles-list .al-icon.bg-Business {
  background-color: #A17F68;
}
.articles-list .al-icon.bg-Environment {
  background-color: #98CA9F;
}
.articles-list .al-icon.bg-Education {
  background-color: #7FBEDD;
}

.articles-list .al-icon .ali-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.articles-list .al-icon .ali-item span {
  display: flex;
  height: 20px;
  line-height: 20px;
  align-items: center;
}
.articles-list .al-icon .ali-item span img {
  margin-right: 8px;
}
</style>
