<template>
  <el-icon style="font-size: 13px; margin-right: 5px;"><Compass /></el-icon>
</template>

<script>
export default {
  name: "Society"
}
</script>

<style scoped>

</style>