<template>
  <font-awesome-icon icon="fa-regular fa-newspaper" style="font-size: 10px; margin-right: 5px;"/>
</template>

<script>
export default {
  name: "News"
}
</script>

<style scoped>

</style>