<template>
  <font-awesome-icon icon="fa-graduation-cap" style="font-size: 10px; margin-right: 5px;"/>
</template>

<script>
export default {
  name: "Edu"
}
</script>

<style scoped>

</style>