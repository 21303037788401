<template>
  <font-awesome-icon icon="fa-regular fa-comment-dots" style="font-size: 10px; margin-right: 5px;"/>
</template>

<script>
export default {
  name: "Interview"
}
</script>

<style scoped>

</style>